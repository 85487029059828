import Walkway from 'walkway.js';

let hero_toggles = document.querySelectorAll('[data-hero-toggle]');

if (document.querySelector('#kbb-triangles')) {
    let svg = new Walkway({
        selector: '#kbb-triangles',
        duration: 3000,
    });

    svg.draw();
}

hero_toggles.forEach(toggle => {
    let targets = document.querySelectorAll(toggle.dataset.heroToggle);

    toggle.addEventListener('mouseover', e => {
        hero_toggles.forEach(other_toggle => {
            other_toggle.classList.remove('active');
        });

        toggle.classList.add('active');

        targets.forEach(target => {
            target.parentNode.querySelectorAll('[data-sibling]').forEach(sibling => {
                sibling.classList.remove('active');
            });
            
            target.classList.add('active');
        });
    });
});
import Slider from '../lib/Slider';

window.addEventListener('DOMContentLoaded', () => {
    // Home Hero Slider
    if(document.querySelector('[data-home-hero-slider]')) {

        let sliders = document.querySelectorAll("[data-home-hero-slider]");
        sliders.forEach(slider => {
            slider = new Slider(slider, {
                onSwitchStart: function() {
                    let active_slide = this.slides[this.active_index];
                    if(active_slide) {
                        let video_el = active_slide.querySelector('[data-video-id]');

                        if(video_el) {
                            video_el.pause();
                        }
                    }
                },
                onSwitchEnd: function() {
                    let active_slide = this.slides[this.active_index];
                    if(active_slide) {
                        let video_el = active_slide.querySelector('[data-video-id]');

                        // if video background and not manually paused, start video
                        if(video_el && !active_slide.dataset.pause) {
                            video_el.play();
                        }

                        // set nav background
                        let slider_nav = this.slider_el.querySelector('[data-slider-nav]');
                        slider_nav.classList.remove('home-hero-bullets-dark');
                        if(typeof active_slide.dataset.darkNav !== 'undefined') {
                            slider_nav.classList.add('home-hero-bullets-dark');
                        }
                    }
                }
            });

            // set up slide navigation
            slider.getSlides().forEach((slide) => {
                let pause_button = slide.querySelector('[data-pause-toggle]');
                let video_el = slide.querySelector('[data-video-id]');
                if(pause_button) {
                    pause_button.addEventListener('click', (e) => {
                        toggleVideoPause(e, slide, video_el);
                    });
                }
            });
        });
    }
});

function toggleVideoPause(e, el, video_el) {
    e && event.preventDefault();

    let pause_button = el.querySelector('[data-pause-toggle]');

    if(el.dataset.pause) {
        delete el.dataset.pause;
        video_el.play();

        if(pause_button) {
            pause_button.classList.add('button-pause');
            pause_button.classList.remove('button-play');

            pause_button.title = "Pause";
        }
    }
    else {
        el.dataset.pause = true;
        video_el.pause();

        if(pause_button) {
            pause_button.classList.remove('button-pause');
            pause_button.classList.add('button-play');

            pause_button.title = "Play";
        }
    }
}
let restrict_to_states = document.querySelectorAll('[data-restrict-to-state]');

if(restrict_to_states.length) {
    const VisitorAPI=function(t,e,a){var s=new XMLHttpRequest;s.onreadystatechange=function(){var t;s.readyState===XMLHttpRequest.DONE&&(200===(t=JSON.parse(s.responseText)).status?e(t.data):a(t.status,t.result))},s.open("GET","https://api.visitorapi.com/api/?pid="+t),s.send(null)};
    
    VisitorAPI(
        "8oey4p8LXBbsk2Vfegeo",
        function(data){
            setStateAccess(data?.region || null);
        },
        function(errorCode, errorMessage){
            setStateAccess(null);
            console.log(errorCode, errorMessage)
        }
    );
}

function setStateAccess(state) {
    let restrict_to_states = document.querySelectorAll('[data-restrict-to-state]');
    restrict_to_states.forEach(restrict_to_state => {
        const allowed_states = restrict_to_state.dataset.statesAvailable.split(',');
        restrict_to_state.classList.remove('error');
        restrict_to_state.classList.remove('match');
        if(allowed_states.indexOf(state) >= 0) {
            restrict_to_state.classList.add('match');
        }
        else {
            restrict_to_state.classList.add('error');
        }
    });
}

let simple_accordion_items = document.querySelectorAll('.simple-accordion-item');

if (simple_accordion_items.length) {
    simple_accordion_items.forEach(item => {
        item.querySelector('.simple-accordion-item-toggle').addEventListener('click', e => {
            e.preventDefault();

            item.closest('.simple-accordion-item').classList.toggle('is-open');
        });
    });
}
let content_navigation = document.querySelector('.content-navigation-container');

if (content_navigation) {
    let content_navigation_anchor = content_navigation.querySelector('.content-navigation-anchor');

    window.requestAnimationFrame(() => {
        checkContentNavigationPosition();
    });

    var checkContentNavigationPosition = () => {
        let content_bounds = content_navigation.getBoundingClientRect().y + content_navigation.offsetHeight;
        content_navigation_anchor.classList.toggle('is-visible', content_bounds < 0);

        window.requestAnimationFrame(() => {
            checkContentNavigationPosition();
        });
    }
}
window.addEventListener("load", function() {
    /* lazy load vidyard videos */
    var lazyVidyards = [].slice.call(document.querySelectorAll("[data-lazyload-vidyard]"));

    if(lazyVidyards.length) {
        import(/* webpackChunkName: "vidyard" */ '../lib/Vidyard').then(({default: Vidyard}) => {
            if ("IntersectionObserver" in window) {
                var lazyVidyardObserver = new IntersectionObserver(function(entries, observer) {
                    entries.forEach(function(vidyard) {
                        if (vidyard.isIntersecting) {
                            vidyard.target.removeAttribute('data-lazyload-vidyard');
                            Vidyard.getInstanceByElement(vidyard.target)?.loadVideo();
                        }
                    });
                });

                lazyVidyards.forEach(function(vidyard) {
                    lazyVidyardObserver.observe(vidyard);
                });
            }
        });
    }

    /* lazy load youtub videos */
    var lazyYouTubes = [].slice.call(document.querySelectorAll("[data-lazyload-youtube]"));
    if(lazyYouTubes.length) {
        import(/* webpackChunkName: "youtube" */ '../lib/YouTube').then(({default: YouTube}) => {
            if ("IntersectionObserver" in window) {
                var lazyYouTubeObserver = new IntersectionObserver(function(entries, observer) {
                    entries.forEach(function(youtube) {
                        if (youtube.isIntersecting) {
                            youtube.target.removeAttribute('data-lazyload-youtube');
                            YouTube.getInstanceByElement(youtube.target)?.loadVideo();
                        }
                    });
                });

                lazyYouTubes.forEach(function(youtube) {
                    lazyYouTubeObserver.observe(youtube);
                });
            }
        });
    }
});
let contactHeaderBlock = document.getElementById('contact-success-heading');
let contactHeader = contactHeaderBlock ? contactHeaderBlock.parentNode.querySelector('.interior-hero-headline') : null;
let nameParam = null;

if(URLSearchParams) {
    let queryParams = new URLSearchParams(window.location.search);
    
    if(queryParams.has('fname')) {
        nameParam = queryParams.get('fname');
    }
}

if(contactHeader && nameParam) {
    contactHeader.innerHTML += `<br />${nameParam}`;
}
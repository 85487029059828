let options = {
    root: document.querySelector('[page]'),
    rootMargin: '0px',
    threshold: 0.1
}

let setInview = (entries, observer) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            entry.target.dataset.inview = true;
            observer.unobserve(entry.target);
        }
    });
}

let observer = new IntersectionObserver(setInview, options);
let targets = document.querySelectorAll('[data-inview="false"]');
targets.forEach(target => {
    observer.observe(target);
});
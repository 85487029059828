let options_menu = {
  root: null,
  rootMargin: '0px',
  threshold: 0.2
}
let floating_nav = document.querySelector('.sidebar-contact');
if (floating_nav) {
  let setInviewMenu = (entries, observer) => {
    entries.forEach(entry => {
        if (entry.isIntersecting && !floating_nav.classList.contains('is-hidden')) {
            floating_nav.classList.add('is-hidden');
        } else if (!entry.isIntersecting && floating_nav.classList.contains('is-hidden')) {
            floating_nav.classList.remove('is-hidden');
        }
    });
  }
  let observer_menu = new IntersectionObserver(setInviewMenu, options_menu);
  let targets_menu = document.querySelectorAll('[data-inview-menu]');
  targets_menu.forEach(target => {
    observer_menu.observe(target);
  });
}

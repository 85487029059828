function onRambleLoad() {
    Ramble.init({
        code: "e1ae95197a",
        realtimeUrl: "https://realtime.ramblechat.com",
        fileStoreUrl: "https://cdn.ramblechat.com",
        resolveUTMVariables: true,
        onInit: onRambleInit,
        // other options:
        // initialName: string - use value as visitor name
        // initialEmail: string - use value as visitor email
        // page: string - use value as the page
        // customVariables: object - pass any custom variables to attach to the chat
        // onButtonClick(show: boolean, firstTime: boolean) - button click callback
        // onButtonDisplayed(buttonEl: HTMLElement) - button appear on the screen callback
        // onInit(params: { visitorUuid: string, connectionType: "new" | "restored", chatUuid?: string }) - Ramble init callback
        // onChatRequested(params: { visitorUuid: string; chatUuid: string }) - Visitor requested chat callback
        // onChatAnswered(params: { visitorUuid: string; chatUuid: string }) - Chat answered callback
        // onChatCompleted(params: { visitorUuid: string; chatUuid: string; type: "missed" | "answered" }) - Chat completed callback
        // onChatMessage(params: { visitorUuid: string; chatUuid: string }) - New message arrived callback
    });
}

function onRambleInit() {
    const initButton = document.getElementById('ramble-init');
    initButton?.remove();

    window.setTimeout(() => {
        document.querySelector('#ramble-ew iframe')?.contentDocument?.querySelector('.frame-content div')?.click();
    }, 100);
}

function loadRambleScript() {
    var s = document.createElement("script"); 
    s.onerror = lr; 
    s.onload = onRambleLoad; 
    document.head.appendChild(s); 
    s.async = true; 
    s.src = "https://snippet.ramblechat.com/ramble.snippet.js";
}

function lr(e) { 
    console.error("Unable to load ramble snippet: " + e.message); 
};

const initButton = document.getElementById('ramble-init');
initButton?.addEventListener('click', () => {
    initButton.classList.add('loading');
    loadRambleScript();
});

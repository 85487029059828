import Hammer from '../lib/Hammer';

class Slider {
    constructor(el = null, options = {}) {
        this.slider_el = el; // dom element representing slider

        // make sure we have an id for this slider
        this.slider_el.id = this.slider_el.id || `atd-slider-${Slider.sliders.length + 1}`;

        this.slides = this.slider_el.querySelectorAll('[data-slide]');
        this.slide_navs = this.slider_el.querySelectorAll('[data-slide-index]');
        
        this.active_index = 0;
        this.slides.forEach((slide, i) => {
            if(slide.classList.contains('active')) {
                this.active_index = i;
            }
        });
        
        if(this.slide_navs.length) {
            this.slide_navs[this.active_index].classList.add('active');
        }

        this.slide_navs.forEach((nav) => {
            nav.addEventListener('click', (e) => {this.onSlideNavigation(e)});
        });

        //set up swipe events
        this.swipe_controller = new Hammer(this.slider_el);
        
        this.swipe_controller.on('swipeleft', (e) => {
            e && e.preventDefault();
            this.nextSlide();
        });
        
        this.swipe_controller.on('swiperight', (e) => {
            e && e.preventDefault();
            this.previousSlide();
        });

        this.is_transitioning = false;

        this.options = Object.assign({
            onSwitchStart: null, // callback on switchslide start
            onSwitchEnd: null // callback on switchslide end
        }, options);

        // add instance to static slider array
        Slider.sliders.push(this);
    }

    onSlideNavigation(e) {
        e && e.preventDefault();

        let index = parseInt(e.target.dataset.slideIndex);

        if(!isNaN(index)) {
            this.switchSlide(index);
        }
    }

    previousSlide() {
        if(this.active_index > 0) {
            this.switchSlide(this.active_index - 1);
        }
        else {
            this.switchSlide(this.slides.length - 1);
        }
    }

    nextSlide() {
        if(this.active_index < this.slides.length - 1) {
            this.switchSlide(this.active_index + 1);
        }
        else {
            this.switchSlide(0);
        }
    }

    switchSlide(slide_in_index) {
        if(this.is_transitioning) {
            return;
        }

        let slide_in = this.slides[slide_in_index];
        let slide_out_index = this.active_index;
        let slide_out = this.slides[slide_out_index];

        slide_in.classList.add('transition-in');
        slide_out.classList.add('transition-out');
        slide_out.classList.remove('active');

        this.is_transitioning = true;

        this.slide_navs.forEach((nav, i) => {
            if(slide_in_index === i) {
                nav.classList.add('active');
            }
            else {
                nav.classList.remove('active');
            }
        });

        if(typeof this.options.onSwitchStart === 'function') {
            this.options.onSwitchStart.call(this);
        }

        window.setTimeout(() => {
            slide_in.classList.remove('transition-in');
            slide_out.classList.remove('transition-out');

            slide_in.classList.add('active');

            if(typeof this.options.onSwitchEnd === 'function') {
                this.options.onSwitchEnd.call(this);
            }

            this.is_transitioning = false;
        }, 250);

        this.active_index = slide_in_index;
    }

    getSlides() {
        return this.slides;
    }
}

// static array to hold generated sliders
Object.defineProperty(Slider, 'sliders', {
    get: function() { return this.hasOwnProperty('_sliders') ? this._sliders : void 0; },
    set: function(v) { this._sliders = v; }
});

// init stati slider array
Slider.sliders = [];

export default Slider;
